import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-flexbox-grid"
import MetricCard from "./MetricCard"
import { buildSearchUrl } from "../../util/string"
import Styles from "./Metrics.module.css"
const Immutable = require("seamless-immutable").static

const Metrics = props => {
  const { statistics, filters, searchFilters } = props

  const currentDate = () => {
    return `vuoden ${new Date().getFullYear()} aikana`
  }

  /*
  const currentDateOld = () =>
    `tilanne ${new Intl.DateTimeFormat("fi-Fi", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(new Date())}`
  */

  const getTimeEndOFYear = () => {
    if (filters.year === new Date().getFullYear() || filters.year === 0) {
      return currentDate()
    }
    return `tilanne 31. joulukuuta ${filters.year}`
  }

  const getTimeDuringYear = () => {
    if (filters.year === new Date().getFullYear() || filters.year === 0) {
      return currentDate()
    }
    return `vuoden ${filters.year} aikana`
  }

  const getParams = () => {
    const params = {}
    if (filters.region && filters.region !== "%") params.region = [{ value: filters.region }]
    if (filters.industry && filters.industry !== "%")
      params.industry = [{ value: filters.industry }]
    if (filters.year) {
      params.startYearFrom = filters.year.toString()
      if (filters.year !== new Date().getFullYear()) params.startYearTo = filters.year.toString()
    } else if (filters.year === 0) {
      params.startYearFrom = new Date().getFullYear().toString()
      // params.startYearTo = new Date().getFullYear().toString()
    }
    const newFilters = Immutable.asMutable(searchFilters)
    newFilters.startYearFrom = params.startYearFrom
    newFilters.startYearTo = params.startYearTo
    return params
  }

  if (Object.keys(statistics).length) {
    return (
      <Row className={Styles.content}>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <MetricCard
            title="Aktiiviset yritykset"
            metrics={statistics.inCompanyForms.currentlyActive}
            time={getTimeEndOFYear()}
            modalDirection="left"
          >
            <p>
              Aktiiviset yritykset näyttää kaupunkiseudun aktiivisten yritysten lukumäärän.
              <br />
              <br />
              Yritys on aktiivinen kun se on rekisteröity kaupparekisteriin sekä vähintään yhteen toiseen rekisteriin
              (ALV-rekisteriin, ennakkoperintärekisteriin, tai työnantajarekisteriin) tai yrityksellä on vähintään
              yksi tilinpäätös viimeiseltä kolmelta tilikaudelta, eikä sillä ole mitään erityistilannetta (konkurssi,
              saneeraus, selvitystila, toiminta keskeytynyt tai toiminta lakannut).
              Viimeisen kuuden kuukauden sisään perustetut yritykset ovat automaattisesti aktiivisia.
              <br />
              <br />
              Sisältää seuraavat yhtiömuodot: OY, OYJ, TMI, KY, AY, OSK.
              <br />
              <br />
              Tietolähde: Profinder
            </p>
          </MetricCard>
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <MetricCard
            title={["Rekisteröidyt"]}
            metrics={[
              `+${statistics.inCompanyForms.registered}`
            ]}
            to={[`/haku/${buildSearchUrl("", getParams())}`]}
            time={getTimeDuringYear()}
            modalDirection="left"
            multipleMetrics
          >
            <p>
              Rekisteröidyt näyttää valitun kaupunkiseudun Kaupparekisteriin rekisteröityjen
              yritysten lukumäärän.
              <br />
              <br />
              Esivalittuna näytetään kuluvan vuoden aikana rekisteröidyt yritykset.
              <br />
              <br />
              Tietolähde: Profinder
            </p>
          </MetricCard>
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <MetricCard
            title="Poistetut"
            metrics={`-${statistics.inCompanyForms.removed}`}
            time={getTimeEndOFYear()}
            modalDirection="left"
          >
            <p>
              Poistetut näyttää valitun kaupunkiseudun mukaan kuluvana vuonna epäaktiivisiksi määriteltyjen
              yritysten lukumäärän.
              <br />
              <br />
              Tietolähde: Profinder
            </p>
          </MetricCard>
        </Col>
      </Row>
    )
  }
  return null
}

Metrics.propTypes = {
  statistics: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  searchFilters: PropTypes.shape().isRequired,
  setSearchFilters: PropTypes.func.isRequired,
}

export default Metrics
