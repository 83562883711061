// Objects that coincide with revenue selector values
export const REVENUE_VALUES = [
  { min: 1000000, max: 100000000000000 },
  { min: 0, max: 1000000 },
  { min: 0, max: 199999 },
  { min: 200000, max: 399999 },
  { min: 400000, max: 999999 },
  { min: 1000000, max: 1999999 },
  { min: 2000000, max: 9999999 },
  { min: 10000000, max: 19999999 },
  { min: 20000000, max: 49999999 },
  { min: 50000000, max: 99999999 },
  { min: 100000000, max: 100000000000000 },
  { min: 0, max: 100000000000000 },
]

export const CHART_ANIM = {
  duration: 300,
  easing: "quadOut",
}

// TODO: Maybe add some logic for setting these automatically
export const EMPLOYER_LIST_YEAR = 2023 // new Date().getFullYear() - 2
export const REVENUE_LIST_YEAR = 2023 // new Date().getFullYear() - 2
export const TAX_LIST_YEAR = 2021 // new Date().getFullYear() - 2
export const FUNDING_LIST_YEAR = 2023

export const PAGE_TITLE = "Yritystieto | Business Tampere"

export const REGION_OPTIONS = [
  { value: "%", label: "Kaikki kunnat" },
  { value: "tampere", label: "Tampere" },
  { value: "kangasala", label: "Kangasala" },
  { value: "lempäälä", label: "Lempäälä" },
  { value: "nokia", label: "Nokia" },
  { value: "orivesi", label: "Orivesi" },
  { value: "pirkkala", label: "Pirkkala" },
  { value: "vesilahti", label: "Vesilahti" },
  { value: "ylöjärvi", label: "Ylöjärvi" },
]

export const REVENUE_OPTIONS = [
  { value: "1", label: "Yli 1 000 000" },
  { value: "2", label: "Alle 1 000 000" },
  { value: "3", label: "0 - 199 999" },
  { value: "4", label: "200 000 - 399 999" },
  { value: "5", label: "400 000 - 999 999" },
  { value: "6", label: "1 000 000 - 1 999 999" },
  { value: "7", label: "2 000 000 - 9 999 999" },
  { value: "8", label: "10 000 000 - 19 999 999" },
  { value: "9", label: "20 000 000 - 49 999 999" },
  { value: "10", label: "50 000 000 - 99 999 999" },
  { value: "11", label: "Yli 100 000 000" },
  { value: "12", label: "Kaikki" },
]
