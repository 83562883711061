import React, { Component } from "react"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from "victory"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Styles from "./Top10Industries.module.css"
import LoadingSpinner from "../../LoadingSpinner"
import DashboardCard from "../../DashboardCards/DashboardCard"
import colorMap from "../colorMap"
import NotAvailable from "../../NotAvailable"
import { CHART_ANIM } from "../../../util/constants"
import LoadingOverlay from "../../LoadingOverlay"
import InfoModal from "../../InfoModal"
import { ChartTable } from "../../ChartTable"

class Top10Industries extends Component {
  getTableColumns = () => {
    return [
      {
        id: "industry",
        heading: "Toimiala",
      },
      {
        id: "y",
        heading: "Yritysten lukumäärä",
      },
    ]
  }
  renderTop10Industries() {
    const { loading, industrySizes } = this.props

    if (!industrySizes) {
      return null
    }

    if (!industrySizes.total.length) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }
    const sortable = [...industrySizes.total]
    const sortedData = sortable.sort((a, b) => b.amount - a.amount)
    const sliced = sortedData.slice(0, 10)
    const data = sliced.map(({ amount: y, industry }, x) => ({
      x: x + 1,
      y,
      industry,
      label: y,
      color: colorMap[x],
    }))

    return (
      <div className={Styles.container}>
        <LoadingOverlay visible={Boolean(loading && industrySizes)} />
        <VictoryChart
          height={350}
          width={400}
          padding={{ top: 35, right: 100, bottom: 35, left: 0 }}
          domainPadding={10}
          style={{
            parent: {
              // height: "auto",
              display: "flex",
              flex: 0,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              border: "none",
              transform: "rotate(90deg) translateX(-1.5rem)",
              top: 60,
            },
          }}
        >
          <VictoryAxis
            crossAxis
            tickCount={10}
            tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            tickFormat={t => `${t}.`}
            tickLabelComponent={
              <VictoryLabel dy={15} textAnchor="end" verticalAnchor="middle" angle="-90" />
            }
            style={{
              grid: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              axis: { stroke: "none" },
            }}
          />
          <VictoryBar
            animate={CHART_ANIM}
            data={data}
            style={{
              data: {
                fill: ({ datum }) => datum.color,
                transform: "translateX(7px)",
              },
            }}
            sortOrder="descending"
            labelComponent={
              <VictoryLabel
                dx={4}
                dy={8}
                textAnchor="start"
                verticalAnchor="start"
                angle="-90"
                text={({ datum }) => datum.y.toFixed(0)}
              />
            }
            barWidth={16}
            alignment="start"
          />
        </VictoryChart>
        <div className={Styles.industryContainer}>
          <ol className={Styles.industryList}>
            {data.map(({ industry }) => (
              <li className={Styles.industryWrapper} key={industry}>
                <span>{industry || "Ei määritelty"}</span>
              </li>
            ))}
          </ol>
        </div>
        <ChartTable data={data} columns={this.getTableColumns()} title="Top 10 toimialat">
          {this.renderInfo()}
        </ChartTable>
      </div>
    )
  }

  renderContent = () => {
    const { loading, industrySizes } = this.props
    if (loading && !industrySizes) {
      return <LoadingSpinner loading={loading} />
    }
    return this.renderTop10Industries()
  }

  renderInfo = () => (
    <p>
      Top 10 toimialat listaa kunnittain kymmenen palvelun tietokannan yrityksissä eniten esiintyvää
      TOL2-toimialaluokituskuvausta.
      <br />
      <br />
      Tietolähde: Profinder
    </p>
  )

  render() {
    return (
      <DashboardCard
        title="Top 10 toimialat"
        renderModal={<InfoModal position="left">{this.renderInfo()}</InfoModal>}
      >
        {this.renderContent()}
      </DashboardCard>
    )
  }
}
Top10Industries.defaultProps = {
  industrySizes: null,
}

Top10Industries.propTypes = {
  industrySizes: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    industrySizes: state.chartStatistics.data.industrySizes,
  }
}
export default connect(mapStateToProps)(Top10Industries)
