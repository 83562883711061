import React, { Component } from "react"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip, VictoryGroup } from "victory"
import PropTypes from "prop-types"
import RibbonContainer from "../RibbonContainer"
import Styles from "./RdiFinancing.module.css"
import { formatMoney } from "../../util/string"

class RdiFinancing extends Component {
  mkFormatter = labelValue => {
    const number = Math.abs(Number(labelValue))
    if (number >= 1.0e6) {
      return `${number / 1.0e6}m`
    }
    if (number >= 1.0e3) {
      return `${number / 1.0e3}k`
    }
    return number
  }

  renderRdiFinancing() {
    const { company } = this.props

    if (!company) {
      return null
    }
    const legendData = [
      {
        legend: "Avustukset",
        color: "#d15371",
      },
      {
        legend: "Lainat",
        color: "#7a65a7",
      },
      {
        legend: "Yhteensä",
        color: "#ae416a",
      },
    ]

    const fundings = company.fundingInfos.map(it => ({
      x: it.maksuvuosi,
      y: it.avustus,
      label: formatMoney(it.avustus),
    }))
    const loans = company.fundingInfos.map(it => ({
      x: it.maksuvuosi,
      y: it.laina || 0,
      label: formatMoney(it.laina || 0),
    }))
    const combined = company.fundingInfos.map(it => ({
      x: it.maksuvuosi,
      y: it.laina + it.avustus,
      label: formatMoney(it.laina + it.avustus),
    }))

    const tickValues = fundings.map(({ x }) => x)

    const Tooltip = (
      <VictoryTooltip
        cornerRadius={0.8}
        flyoutStyle={{ stroke: "grey", strokeWidth: 0.5, fill: "white" }}
        pointerLength={20}
        centerOffset={{ x: 20 }}
      />
    )

    return (
      <div className={Styles.chartContainer}>
        <div className={Styles.chartLegend}>
          {legendData.map(({ legend, color }) => (
            <div className={Styles.legendWrapper} key={legend}>
              <div className={Styles.square} style={{ backgroundColor: color }} />
              <span className={Styles.legendName}>{legend}</span>
            </div>
          ))}
        </div>
        <VictoryChart
          height={200}
          domainPadding={50}
          padding={{ top: 25, right: 50, bottom: 35, left: 50 }}
          style={{
            parent: {
              // maxWidth: "100rem",
              border: "none",
            },
          }}
        >
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              tickLabels: { fill: "#343434", fontSize: 10 },
            }}
            tickCount={2}
            tickValues={tickValues}
          />
          <VictoryAxis
            dependentAxis
            style={{
              grid: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              axis: { stroke: "transparent" },
              tickLabels: { fill: "#343434", fontSize: 10 },
            }}
            tickCount={4}
            tickFormat={this.mkFormatter}
          />
          <VictoryGroup offset={20}>
            <VictoryBar
              data={fundings}
              labelComponent={Tooltip}
              style={{ data: { fill: "#d15371" } }}
              barWidth={15}
            />
            <VictoryBar
              data={loans}
              labelComponent={Tooltip}
              style={{ data: { fill: "#7a65a7" } }}
              barWidth={15}
            />
            <VictoryBar
              data={combined}
              labelComponent={Tooltip}
              style={{ data: { fill: "#ae416a" } }}
              barWidth={15}
            />
          </VictoryGroup>
        </VictoryChart>
      </div>
    )
  }

  render() {
    return (
      <RibbonContainer title="Business finlandin myöntämä TKI-rahoitus">
        {this.renderRdiFinancing()}
      </RibbonContainer>
    )
  }
}

RdiFinancing.propTypes = {
  company: PropTypes.shape().isRequired,
}

export default RdiFinancing
