import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import "react-dropdown/style.css"
import { Select } from "./Select"

class IndustrySelector extends PureComponent {
  render() {
    const { onChange, value, options } = this.props
    const opts = [{ label: "Kaikki toimialat", value: "%" }, ...options]

    return (
      <Select
        defaultValue="%"
        value={value}
        id="industry-select"
        options={opts}
        onChange={onChange}
        variant="borderless"
      />
    )
  }
}

IndustrySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
}

IndustrySelector.defaultProps = {
  className: "",
  placeholderClassName: "",
  options: [],
}

export default IndustrySelector
