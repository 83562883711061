export const clusterLayer = {
  id: "clusters",
  type: "circle",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": ["step", ["get", "point_count"], "#d15371", 100, "#345ca3", 750, "#44746a"],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
}

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["Chivo Bold"],
    "text-size": 14,
  },
  paint: {
    "text-color": "#ffffff",
  },
}

export const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "symbol",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "icon-image": "marker-pink",
    "icon-anchor": "bottom",
    "icon-offset": [0, 6],
    "icon-padding": 0,
  },
}

export const unclesteredBranchPointLayer = {
  id: "unclustered-branch-point",
  type: "symbol",
  filter: ["all", ["!", ["has", "point_count"]], ["has", "toimipaikkaId"]],
  layout: {
    "icon-image": "marker-purple",
    "icon-anchor": "bottom",
    "icon-offset": [0, 6],
    "icon-padding": 0,
  },
}
