import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Styles from "./MetricCard.module.css"
import InfoModal from "../InfoModal"

export default class MetricCard extends React.PureComponent {
  renderModal = () => {
    const { modalDirection, children } = this.props
    if (children) {
      return <InfoModal position={modalDirection}>{children}</InfoModal>
    }
    return null
  }

  renderContent = (paramMetrics, paramTo) => {
    const { metrics, to, multipleMetrics } = this.props
    const content = paramMetrics || metrics
    const isLink = multipleMetrics ? paramTo : to
    const LinkOrDiv = isLink ? Link : "div"
    if (typeof content === "number" || typeof content === "string") {
      return (
        <LinkOrDiv className={Styles.metricContent} to={isLink}>
          {content}
        </LinkOrDiv>
      )
    }
  }

  renderMultipleMetrics = () => {
    const { title, metrics, to } = this.props
    const count = title.length
    return (
      <div className={Styles.multipleMetricsContainer}>
        {title.map((heading, index) => {
          return (
            <div key={heading}>
              {this.renderTitle(heading, count === index + 1)}
              {this.renderContent(metrics[index], to && to[index])}
            </div>
          )
        })}
      </div>
    )
  }

  renderTitle = (paramTitle, includeModal) => {
    const { title } = this.props
    const text = paramTitle || title
    if (typeof text === "string") {
      return (
        <div className={Styles.headingWrapper}>
          <div className={Styles.headingWithModal}>
            <h3 className={Styles.metricHeading}>{text}</h3>
            {!paramTitle && this.renderModal()}
            {paramTitle && includeModal && this.renderModal()}
          </div>
        </div>
      )
    }
  }

  renderMetrics = () => {
    const { multipleMetrics } = this.props
    if (!multipleMetrics) {
      return (
        <>
          {this.renderTitle()}
          {this.renderContent()}
        </>
      )
    }

    return this.renderMultipleMetrics()
  }

  render() {
    const { time } = this.props

    return (
      <div className={Styles.metricCard}>
        {this.renderMetrics()}
        <span className={Styles.metricSubContent}>{time}</span>
      </div>
    )
  }
}
MetricCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  metrics: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]).isRequired,
  time: PropTypes.string.isRequired,
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  modalDirection: PropTypes.oneOf(["right", "left"]),
  multipleMetrics: PropTypes.bool,
}
MetricCard.defaultProps = {
  children: null,
  to: "",
  modalDirection: "right",
  multipleMetrics: false,
}
