import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DashboardCard from "./DashboardCards/DashboardCard"
import Styles from "./CompaniesOnMap.module.css"
import SelectionButtons from "./SelectionButtons"
import { Map } from "./Map/Map"

import MarkerPink from "../assets/MarkerPink.svg"
import MarkerPurple from "../assets/MarkerPurple.svg"

const nameMap = {
  top10employer: "Suurimmat työllistäjät",
  top10revenue: "Suurimmat liikevaihdon kasvattajat",
  top10taxPayers: "Suurimmat veronmaksajat",
}

class CompaniesOnMap extends React.Component {
  state = {
    selectedFilter: "top10employer",
    showingInfoWindow: false,
    activeMarker: {},
    selectedCompany: {},
  }

  selectFilter = selectedFilter => {
    this.setState({
      selectedFilter,
    })
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedCompany: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })
  }

  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const { top10revenue, top10employer, top10taxPayers } = this.props
    const data = {
      top10employer,
      top10revenue,
      top10taxPayers,
    }

    const { selectedFilter } = this.state

    return (
      <DashboardCard title="Näytä yritykset kartalla">
        <SelectionButtons
          selection={Object.keys(data).map(id => ({
            id,
            title: nameMap[id],
          }))}
          selected={selectedFilter}
          onClick={this.selectFilter}
        />
        <div className={Styles.mapContainer}>
          <div className={Styles.mapWrapper}>
            <Map companies={data[selectedFilter]} />
          </div>
          <div className={Styles.mapMarkerContainer}>
            <div className={Styles.mapMarkerWrapper}>
              <img
                className={Styles.icon}
                src={MarkerPink}
                alt="pääasiallinen toimisto karttamerkki"
              />
              <span className={Styles.mapMarkerText}>Pääasiallinen toimipiste</span>
            </div>
            <div className={Styles.mapMarkerWrapper}>
              <img className={Styles.icon} src={MarkerPurple} alt="sivutoimipste karttamerkki" />
              <span className={Styles.mapMarkerText}>Sivutoimipiste</span>
            </div>
          </div>
        </div>
      </DashboardCard>
    )
  }
}

CompaniesOnMap.propTypes = {
  top10revenue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top10employer: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top10taxPayers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const mapStateToProps = state => ({
  top10revenue: state.revenueList.data,
  top10employer: state.employerList.data,
  top10taxPayers: state.taxList.data,
})

export default connect(mapStateToProps)(CompaniesOnMap)
