import { combineReducers } from "redux"
import revenueListReducer from "./revenueListReducer"
import regionStatisticsReducer from "./regionStatisticsReducer"
import searchReducer from "./searchReducer"
import companyReducer from "./companyReducer"
import branchReducer from "./branchReducer"
import industryReducer from "./industryReducer"
import profinderIndustryReducer from "./profinderIndustryReducer"
import filterOptionReducer from "./filterOptionReducer"
import fundingReducer from "./fundingReducer"
import chartStatisticsReducer from "./chartStatisticsReducer"
import employerListReducer from "./employerListReducer"
import taxListReducer from "./taxListReducer"

const rootReducer = combineReducers({
  taxList: taxListReducer,
  employerList: employerListReducer,
  chartStatistics: chartStatisticsReducer,
  revenueList: revenueListReducer,
  regionStatistics: regionStatisticsReducer,
  search: searchReducer,
  company: companyReducer,
  branch: branchReducer,
  industries: industryReducer,
  profinderIndustries: profinderIndustryReducer,
  filterOptions: filterOptionReducer,
  funding: fundingReducer,
})

export default rootReducer
