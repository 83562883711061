import Immutable from "seamless-immutable"
import {
  FETCH_FUNDING,
  FETCH_FUNDING_ERROR,
  FETCH_FUNDING_OK,
  CHANGE_FUNDING_PARAMS,
  CLEAR_FUNDING_PARAMS,
} from "../actions/fundingActions"

const initialState = Immutable({
  loading: false,
  firstYearFunding: 0,
  secondYearFunding: 0,
  firstYearLoans: 0,
  secondYearLoans: 0,
  error: null,
  params: { region: "", industry: "" },
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FUNDING:
      return state.merge({ loading: true })
    case FETCH_FUNDING_OK:
      return state.merge({
        firstYearFunding: action.firstYearFunding,
        secondYearFunding: action.secondYearFunding,
        firstYearLoans: action.firstYearLoans,
        secondYearLoans: action.secondYearLoans,
        loading: false,
      })
    case FETCH_FUNDING_ERROR:
      return state.merge({ error: action.error, loading: false })
    case CHANGE_FUNDING_PARAMS:
      return state.merge({ params: action.data })
    case CLEAR_FUNDING_PARAMS:
      return state.merge({ params: initialState.params })
    default:
      return state
  }
}
