import React from "react"
import PropTypes from "prop-types"
import Styles from "./CompanyOnMap.module.css"
import RibbonContainer from "../RibbonContainer"
import { Map } from "../Map/Map"

import MarkerPink from "../../assets/MarkerPink.svg"
import MarkerPurple from "../../assets/MarkerPurple.svg"

class CompanyOnMap extends React.Component {
  render() {
    const { markers, showPopup } = this.props
    if (!markers || markers.length === 0) {
      return null
    }
    return (
      <RibbonContainer title="Yrityksen sijainti kartalla">
        <div className={Styles.mapContainer}>
          <div className={Styles.mapWrapper}>
            <Map companies={markers} showPopup={showPopup} />
          </div>
        </div>
        <div className={Styles.mapMarkerContainer}>
          <div className={Styles.mapMarkerWrapper}>
            <img
              className={Styles.icon}
              src={MarkerPink}
              alt="pääasiallinen toimisto karttamerkki"
            />
            <span className={Styles.mapMarkerText}>Pääasiallinen toimipiste</span>
          </div>
          <div className={Styles.mapMarkerWrapper}>
            <img className={Styles.icon} src={MarkerPurple} alt="sivutoimipste karttamerkki" />
            <span className={Styles.mapMarkerText}>Sivutoimipiste</span>
          </div>
        </div>
      </RibbonContainer>
    )
  }
}

CompanyOnMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showPopup: PropTypes.bool.isRequired,
}

export default CompanyOnMap
