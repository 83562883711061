import React, { Component } from "react"
import PropTypes from "prop-types"
import { VictoryLine, VictoryChart, VictoryScatter, VictoryAxis, VictoryTooltip } from "victory"
import { connect } from "react-redux"
import LoadingSpinner from "../../LoadingSpinner"
import DashboardCard from "../../DashboardCards/DashboardCard"
import Styles from "./SetUpCompany.module.css"
import NotAvailable from "../../NotAvailable"
import { CHART_ANIM } from "../../../util/constants"
import LoadingOverlay from "../../LoadingOverlay"
import InfoModal from "../../InfoModal"
import { ChartTable } from "../../ChartTable"

class SetUpCompany extends Component {
  formatYearToShort = year => `'${year.toString().slice(-2)}`

  getDataColumns = () => {
    return [
      {
        id: "y",
        heading: "Perustettujen yritysten lukumäärä",
      },
      {
        id: "year",
        heading: "Vuosi",
      },
    ]
  }
  renderSetUpCompany() {
    const { loading, foundedByYear } = this.props

    if (!foundedByYear) return null

    const filteredData = foundedByYear.total.filter(
      ({ year }) => year > 2008 && year < new Date().getFullYear()
    )

    const data = filteredData.map(({ amount: y, year }) => ({
      x: year,
      y,
      year,
    }))

    if (filteredData.length === 0) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }

    return (
      <div className={Styles.victoryContainer}>
        <LoadingOverlay visible={Boolean(loading && foundedByYear)} />
        <VictoryChart
          height={350}
          width={400}
          padding={{ top: 15, right: 50, bottom: 60, left: 70 }}
        >
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "transparent" },
              tickLabels: { fill: "#000000" },
            }}
            tickCount={data.length}
            tickFormat={this.formatYearToShort}
          />
          <VictoryAxis
            dependentAxis
            style={{
              grid: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              axis: { stroke: "transparent" },
            }}
            tickCount={6}
          />
          <VictoryLine
            animate={CHART_ANIM}
            data={data}
            style={{ data: { stroke: "#7a65a7", strokeWidth: 2.5 } }}
          />
          <VictoryScatter
            animate={CHART_ANIM}
            events={[
              {
                target: "data",
                childName: "all",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      { target: "data", mutation: () => ({ active: true }) },
                      { target: "labels", mutation: () => ({ active: true }) },
                    ]
                  },
                  onMouseOut: () => {
                    return [
                      { target: "data", mutation: () => ({ active: false }) },
                      { target: "labels", mutation: () => ({ active: false }) },
                    ]
                  },
                },
              },
            ]}
            size={({ active }) => (active ? 12 : 6)}
            data={data}
            style={{ data: { fill: ({ active }) => (active ? "#d15371" : "#638ec8") } }}
            labels={({ datum }) => datum.y}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0.8}
                flyoutStyle={{ stroke: "grey", strokeWidth: 0.5, fill: "white" }}
                pointerLength={20}
                centerOffset={{ x: 20 }}
              />
            }
          />
        </VictoryChart>
        <ChartTable data={data} columns={this.getDataColumns()} title="Perustetut yritykset">
          {this.renderInfo()}
        </ChartTable>
      </div>
    )
  }

  renderContent = () => {
    const { loading, foundedByYear } = this.props
    if (loading && !foundedByYear) return <LoadingSpinner loading={loading} />
    return this.renderSetUpCompany()
  }

  renderInfo = () => {
    return (
      <p>
        Perustetut yritykset esittää kunnittain ja toimialoittain kunakin vuonna perustettujen
        aktiivisten yritysten kokonaismäärän.
        <br />
        <br />
        Tietolähde: Profinder
      </p>
    )
  }
  render() {
    return (
      <DashboardCard
        title="Perustetut yritykset"
        renderModal={<InfoModal position="left">{this.renderInfo()}</InfoModal>}
      >
        {this.renderContent()}
      </DashboardCard>
    )
  }
}

SetUpCompany.defaultProps = {
  foundedByYear: null,
}

SetUpCompany.propTypes = {
  foundedByYear: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  foundedByYear: state.chartStatistics.data.foundedByYear,
})

export default connect(mapStateToProps)(SetUpCompany)
