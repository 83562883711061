import * as isEmpty from "lodash.isempty"

export const getInitial = string => string.substring(0, 1).toUpperCase()

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const formatMoney = number =>
  new Intl.NumberFormat("fi-FI", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(number)

export const formatNumber = number =>
  new Intl.NumberFormat("fi-FI", {
    style: "decimal",
    minimumFractionDigits: 0,
  }).format(number)

export const buildSearchUrl = (searchValue, filters) => {
  const { startYearFrom, startYearTo } = filters
  let searchUrl = encodeURI(searchValue) || "-"
  if (!isEmpty(filters)) {
    searchUrl += "?"
  }

  searchUrl += Object.keys(filters)
    .map(key => {
      if (!isEmpty(filters[key])) {
        if (key === "startYearFrom") {
          return `startYearFrom=${encodeURI(startYearFrom)}`
        }
        if (key === "startYearTo") {
          return `startYearTo=${encodeURI(startYearTo)}`
        }
        if (filters[key].length > 1) {
          if (key === "revenue") {
            return filters[key]
              .map(
                k => `revenues[]=${encodeURI(k.value.minRevenue)},${encodeURI(k.value.maxRevenue)}`
              )
              .join("&")
          }
          if (key === "personnel") {
            return filters[key]
              .map(
                k =>
                  `personnels[]=${encodeURI(k.value.minPersonnel)},${encodeURI(
                    k.value.maxPersonnel
                  )}`
              )
              .join("&")
          }
          return filters[key].map(k => `${key}[]=${encodeURI(k.value)}`).join("&")
        }
        if (key === "revenue") {
          return `revenues[]=${encodeURI(filters[key][0].value.minRevenue)},${encodeURI(
            filters[key][0].value.maxRevenue
          )}`
        }
        if (key === "personnel") {
          return `personnels[]=${encodeURI(filters[key][0].value.minPersonnel)},${encodeURI(
            filters[key][0].value.maxPersonnel
          )}`
        }
        return `${key}[]=${encodeURI(filters[key][0].value)}`
      }
      return null
    })
    .filter(o => !isEmpty(o))
    .join("&")

  return searchUrl
}
