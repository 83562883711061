import React from "react"
import PropTypes from "prop-types"
import Styles from "./DashboardCard.module.css"

export default class DashboardCard extends React.PureComponent {
  render() {
    const { title, forwardRef, children, renderModal } = this.props
    return (
      <div className={Styles.card} ref={forwardRef}>
        <div className={Styles.headingWrapper}>
          <h3 className={Styles.heading}>{title}</h3>
          {renderModal}
        </div>
        <div className={Styles.content}>{children}</div>
      </div>
    )
  }
}
DashboardCard.defaultProps = {
  children: "",
  renderModal: null,
}

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  renderModal: PropTypes.element,
}
