import Immutable from "seamless-immutable"
import { FETCH_BRANCH, FETCH_BRANCH_OK, FETCH_BRANCH_ERROR } from "../actions/branchActions"

const initialState = Immutable({
  loading: false,
  data: {},
  error: null,
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANCH:
      return state.merge({ loading: true })
    case FETCH_BRANCH_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_BRANCH_ERROR:
      return state.merge({ error: action.error, loading: false })
    default:
      return state
  }
}
