import { Link } from "react-router-dom"
import React from "react"
import Styles from "./ButtonLink.module.css"
import PropTypes from "prop-types"

export const ButtonLink = ({ href, to, disabled, children, ...rest }) => {
  if (disabled) return <span>{children}</span>
  if (href)
    return (
      <a className={Styles.button} href={href} {...rest}>
        {children}
      </a>
    )
  return (
    <Link className={Styles.button} to={to}>
      {children}
    </Link>
  )
}

ButtonLink.propTypes = {
  to: PropTypes.any,
  href: PropTypes.string,
  disabled: PropTypes.bool,
}

ButtonLink.defaultProps = {
  disabled: false,
}
