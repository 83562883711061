import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Grid } from "react-flexbox-grid"
import { withRouter } from "react-router-dom"
import * as RegionStatisticsActions from "../actions/regionStatisticsActions"
import * as FilterActions from "../actions/filterOptionActions"
import * as SearchActions from "../actions/searchActions"
import Styles from "./Main.module.css"
import DropdownFilter from "../components/DropdownFilter"
import Metrics from "../components/Metrics/Metrics"
import DashboardCards from "../components/DashboardCards/DashboardCards"
import { Seo } from "../components/Seo"

const Main = props => {
  const {
    regionStatistics,
    loadingRegionStatistics,
    industryOptions,
    location,
    history,
    filters,
    searchFilters,
    handleFilterChange,
  } = props

  const stickyCheck = useRef(null)
  const [isSticky, setSticky] = useState(false)

  useEffect(() => {
    observe()
    return () => {
      stickyCheck.current && observer.unobserve(stickyCheck.current)
    }
  }, [stickyCheck])

  // eslint-disable-next-line no-undef
  const observer = new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio === 0) setSticky(true)
      if (entries[0].intersectionRatio === 1) setSticky(false)
    },
    {
      threshold: [0, 1],
    }
  )

  const observe = () => {
    if (stickyCheck && stickyCheck.current) observer.observe(stickyCheck.current)
  }

  return (
    <React.Fragment>
      <Grid fluid className={Styles.pageContent}>
        <div className={Styles.stickyCheck} ref={stickyCheck} />
        <DropdownFilter
          industries={industryOptions}
          loading={loadingRegionStatistics}
          filters={filters}
          isSticky={isSticky}
        />
        <Metrics
          statistics={regionStatistics}
          filters={filters}
          searchFilters={searchFilters}
          setSearchFilters={handleFilterChange}
        />
        <DashboardCards location={location} history={history} filters={filters} />
      </Grid>
      <Seo />
    </React.Fragment>
  )
}

Main.propTypes = {
  regionStatistics: PropTypes.shape().isRequired,
  loadingRegionStatistics: PropTypes.bool.isRequired,
  industryOptions: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  searchFilters: PropTypes.shape().isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
}

Main.defaultProps = {
  industryOptions: [],
}

const mapStateToProps = state => ({
  loadingRegionStatistics: state.regionStatistics.loading,
  regionStatistics: state.regionStatistics.data,
  industryOptions: state.filterOptions.data.industryOptions,
  filters: state.filterOptions.filters,
  searchFilters: state.search.filters,
})

const mapDispatchToProps = dispatch => ({
  fetchRegionStats: (region, industry, year) =>
    dispatch(RegionStatisticsActions.fetchRegionStatistics(region, industry, year)),
  resetFilters: () => dispatch(FilterActions.resetFilters()),
  handleFilterChange: newState => dispatch(SearchActions.handleFilterChange(newState)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
