import Immutable from "seamless-immutable"
import {
  FETCH_PROFINDER_INDUSTRIES,
  FETCH_PROFINDER_INDUSTRIES_OK,
  FETCH_PROFINDER_INDUSTRIES_ERROR,
} from "../actions/profinderIndustryActions"

const initialState = Immutable({
  loading: false,
  data: [],
  error: null,
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFINDER_INDUSTRIES:
      return state.merge({ loading: true })
    case FETCH_PROFINDER_INDUSTRIES_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_PROFINDER_INDUSTRIES_ERROR:
      return state.merge({ error: action.error, loading: false })
    default:
      return state
  }
}
