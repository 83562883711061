import React from "react"
import PropTypes from "prop-types"
import { capitalize } from "../../util/string"
import Styles from "./Info.module.css"
import RibbonContainer from "../RibbonContainer"

const Row = ({ header, text }) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{text}</td>
  </tr>
)

Row.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
}

const check = text => text || "-"
const checkAddress = (address, zip, city) => {
  if (!address || !zip || !city) {
    return "-"
  }
  return `${address}, ${zip} ${city}`
}

const CompanyInfo = ({ company }) => (
  <RibbonContainer title={company.virallinen_nimi}>
    <div className={Styles.info}>
      <table className={Styles.left}>
        <tbody>
          <Row header="Y-tunnus" text={check(company.y_tunnus)} />
          <Row header="Kotipaikka" text={capitalize(check(company.kotikunta))} />
          <Row
            header="Postiosoite"
            text={checkAddress(
              company.postiosoite_yritys,
              company.postios_pnro_yritys,
              company.postios_kunta_yritys
            )}
          />
          <Row
            header="Käyntiosoite"
            text={checkAddress(
              company.kayntiosoite_yritys,
              company.kayntios_pnro_yritys,
              company.kayntios_kunta_yritys
            )}
          />
          <Row header="Toimiala" text={check(company.tol2_selite)} />
          <Row header="Yritysmuoto" text={check(company.yhtiomuoto)} />
        </tbody>
      </table>
      <table className={Styles.right}>
        <tbody>
          <Row header="Puhelin" text={check(company.puhnro_yritys)} />
          <Row header="Sähköposti" text={check(company.email_yritys)} />
          <Row
            header="Kotisivu"
            text={
              company.www_sivu_yritys ? (
                <a href={`//${company.www_sivu_yritys}`} target="_blank" rel="noopener noreferrer">
                  {company.www_sivu_yritys}
                </a>
              ) : (
                "-"
              )
            }
          />
          <Row header="Toimitusjohtaja" text={check(company.toimitusjohtaja)} />
          <Row header="Yrityksen tila" text={company.yrityksen_tila} />
        </tbody>
      </table>
    </div>
  </RibbonContainer>
)

CompanyInfo.propTypes = {
  company: PropTypes.shape().isRequired,
}

export default CompanyInfo
