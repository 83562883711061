import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Select } from "./Select"
import Styles from "./RevenueSelector.module.css"
import { REVENUE_OPTIONS } from "../util/constants"

class RevenueSelector extends PureComponent {
  onSelectChange = obj => {
    this.props.onChange(obj.value)
  }
  render() {
    const { value } = this.props

    return (
      <div className={Styles.revenueSelector}>
        <Select
          onChange={this.onSelectChange}
          options={REVENUE_OPTIONS}
          value={value}
          placeholder="Valitse liikevaihtoluokka"
          variant="grey"
        />
      </div>
    )
  }
}

RevenueSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}

RevenueSelector.defaultProps = {
  className: "",
}

export default RevenueSelector
