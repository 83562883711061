import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Row } from "react-flexbox-grid"
import Styles from "./DropdownFilter.module.css"
import RegionSelector from "./RegionSelector"
import IndustrySelector from "./IndustrySelector"
import * as RevenueListActions from "../actions/revenueListActions"
import * as EmployerListActions from "../actions/employerListActions"
import * as TaxListActions from "../actions/taxListActions"
import * as FundingActions from "../actions/fundingActions"
import * as RegionStatisticsActions from "../actions/regionStatisticsActions"
import * as ChartStatisticsActions from "../actions/chartStatisticsActions"
import * as FilterActions from "../actions/filterOptionActions"
import YearSelector from "./YearSelector"
import { FUNDING_LIST_YEAR, REGION_OPTIONS } from "../util/constants"

const DropdownFilter = props => {
  const {
    fetchChartStats,
    fetchRegionStats,
    changeFundingParams,
    revenueParams,
    changeRevenueParams,
    changeEmployerParams,
    changeTaxParams,
    filters,
    changeFilters,
    industries,
    isSticky,
  } = props
  const { region, industry, year } = filters
  const { revenue } = revenueParams

  const params = useParams()
  const [prevParam, setPrevParam] = useState("")

  useEffect(() => {
    if (params && params.region && params.region !== prevParam) {
      // Ignore param if it's not a valid region
      if (!REGION_OPTIONS.find(r => r.value.toLowerCase() === params.region.toLowerCase())) {
        fetchData(region, industry, year)
        return
      }
      setPrevParam(params.region)
      fetchData(params.region, industry, year)
      changeFilters({ ...filters, region: params.region })
      return
    }
    fetchData(region, industry, year)
  }, [params, region, industry, year])

  const onChangeRegion = ({ value: region }) => {
    changeFilters({ ...filters, region })
  }

  const onChangeIndustry = ({ value: industry }) => {
    changeFilters({ ...filters, industry })
  }

  const onChangeYear = ({ value: year }) => {
    changeFilters({ ...filters, year })
  }

  const fetchData = (region, industry, year) => {
    fetchChartStats(region, industry, year)
    fetchRegionStats(region, industry, year)
    // Default year for Business Finland funding is manual, because the data is updated manually
    changeFundingParams({ year: year || FUNDING_LIST_YEAR, region, industry })
    changeRevenueParams({
      revenue,
      region,
      industry,
      year,
    })
    changeEmployerParams({
      revenue,
      region,
      industry,
      year,
    })
    changeTaxParams({
      revenue,
      region,
      industry,
      year,
    })
  }

  return (
    <Row id="filters" className={`${Styles.card} ${isSticky ? Styles.sticky : null}`}>
      <div className={Styles.filtersContainer}>
        <div className={Styles.left}>
          <div className={Styles.regionWrapper}>
            <RegionSelector value={region} onChange={onChangeRegion} />
          </div>
          <div className={Styles.yearWrapper}>
            <YearSelector value={year} onChange={onChangeYear} />
          </div>
        </div>
        <div className={Styles.industryWrapper}>
          <IndustrySelector value={industry} options={industries} onChange={onChangeIndustry} />
        </div>
      </div>
    </Row>
  )
}

DropdownFilter.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchRegionStats: PropTypes.func.isRequired,
  changeFundingParams: PropTypes.func.isRequired,
  changeRevenueParams: PropTypes.func.isRequired,
  changeEmployerParams: PropTypes.func.isRequired,
  changeTaxParams: PropTypes.func.isRequired,
  revenueParams: PropTypes.shape().isRequired,
  fetchChartStats: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  changeFilters: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  revenueParams: state.revenueList.params,
})

const mapDispatchToProps = dispatch => ({
  changeRevenueParams: params => dispatch(RevenueListActions.changeParams(params)),
  changeEmployerParams: params => dispatch(EmployerListActions.changeParams(params)),
  changeTaxParams: params => dispatch(TaxListActions.changeParams(params)),
  fetchRegionStats: (region, industry, year) =>
    dispatch(RegionStatisticsActions.fetchRegionStatistics(region, industry, year)),
  fetchChartStats: (region, industry, year) =>
    dispatch(ChartStatisticsActions.fetchChartStatistics(region, industry, year)),
  changeFundingParams: params => dispatch(FundingActions.changeFundingParams(params)),
  changeFilters: params => dispatch(FilterActions.changeFilters(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropdownFilter)
