import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import MultiSelector from "./MultiSelector"
import "./RegionMultiSelector.css"
import Checkbox from "../Checkbox/Checkbox"

class RegionMultiSelector extends PureComponent {
  renderOptions = () => {
    const { handleChange, options, filters } = this.props
    if (options) {
      return options.map(o => (
        <li key={o.value}>
          <Checkbox
            label={o.label}
            id={o.label}
            onClick={() => handleChange(o, "region")}
            checked={filters.some(f => f.value === o.value)}
          />
        </li>
      ))
    }
    return null
  }

  render() {
    const { handleChange, options, filters, ...rest } = this.props
    return (
      <div className="region-selector-wrapper">
        <MultiSelector
          text="Alue"
          filters={filters}
          filter="region"
          options={options}
          target="region"
          handleChange={handleChange}
          {...rest}
        >
          <ul>{this.renderOptions()}</ul>
        </MultiSelector>
      </div>
    )
  }
}

RegionMultiSelector.propTypes = {
  toggleSelector: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clearFilters: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
}

export default RegionMultiSelector
