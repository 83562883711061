import React, { Component } from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Footer from "./views/Footer"
import Main from "./views/Main"
import Header from "./views/Header"
import Company from "./views/Company"
import Branch from "./views/Branch"
import SearchResults from "./views/SearchResults"
import NoMatch from "./views/NoMatch"
import DataProtectionInfo from "./views/DataProtectionInfo"
import ServiceInfo from "./views/ServiceInfo"
import { fetchSearchFilters } from "./actions/filterOptionActions"
import { fetchProfinderIndustries } from "./actions/profinderIndustryActions"
import Top500 from "./views/Top500"
import FAQ from "./views/FAQ"

const displayMaintenanceBanner = false

class App extends Component {
  componentDidMount() {
    const { fetchFilters, fetchProfinderIndustries } = this.props
    fetchFilters()
    fetchProfinderIndustries()
  }

  renderInfoBanner() {
    // Maintenance banner
    if (displayMaintenanceBanner) {
      const containerStyle = {
        backgroundColor: "#FFECB3",
        fontFamily: "Montserrat, sans-serif",
        color: "#222",
        position: "fixed",
        fontSize: "16px",
        fontWeight: 400,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 2rem",
        zIndex: 999,
      }
      return (
        <div style={containerStyle}>
          {"Sivustoa päivitetään. Osa tiedoista saattaa olla puutteellisia/vanhentuneita."}
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <React.Fragment>
        <a href="#main-content" className="skip-to-content">
          Mene pääsisältöön
        </a>
        <Header />
        <main id="main-content">
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/kunta/:region" component={Main} />
            <Route path="/haku/:searchValue" component={SearchResults} />
            <Route path="/top500/:listType" component={Top500} />
            <Route path="/yritys/:businessId" component={Company} />
            <Route path="/sivutoimipaikka/:branchId" component={Branch} />
            <Route path="/tietosuojaseloste" component={DataProtectionInfo} />
            <Route path="/tietoa-palvelusta" component={ServiceInfo} />
            <Route path="/usein-kysytyt-kysymykset" component={FAQ} />
            <Route component={NoMatch} />
          </Switch>
          {this.renderInfoBanner()}
        </main>
        <Footer />
      </React.Fragment>
    )
  }
}

App.propTypes = {
  fetchFilters: PropTypes.func.isRequired,
  fetchProfinderIndustries: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  fetchFilters: () => dispatch(fetchSearchFilters()),
  fetchProfinderIndustries: () => dispatch(fetchProfinderIndustries())
})
export default withRouter(connect(null, mapDispatchToProps)(App))
