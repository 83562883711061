import React, { Component } from "react"
import PropTypes from "prop-types"
import MultiSelector from "./MultiSelector"
import "./ProfinderIndustryMultiSelector.css"
import Checkbox from "../Checkbox/Checkbox"

class ProfinderIndustryMultiSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  // Clear search after toggle visible
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      this.setState({ search: '' })
    }
  }

  renderOptions = () => {
    const { handleChange, options, filters } = this.props
    if (options) {
      const filteredOptions = options.filter(o => {
        // Filter logic
        return o.value.toLowerCase().includes(this.state.search)
      })

      if (filteredOptions.length === 0) {
        return <div class="pf-no-search-results">Ei tuloksia</div>
      }

      return filteredOptions.map(o => (
        <div className="pf-checkbox-container" key={o.value}>
          <Checkbox
            label={o.label}
            id={o.label}
            onClick={() => handleChange(o, "service")}
            checked={filters.some(f => f.value === o.value)}
          />
        </div>
      ))
    }
    return null
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value })
  }

  renderActiveSelections() {
    const activeFilters = this.props.filters || []
    const { handleChange } = this.props

    if (!activeFilters.length) return null
    return (
      <div className="pf-active-selections-outer-container">
        <div className="pf-active-selections-container">
          {activeFilters.map(item => {
            return (
              <div className="pf-active-filter-button" key={item.value}>
                {item.label}
                <div class="pf-active-filter-button-delete-container" title="Poista">
                  <div
                    onClick={() => handleChange(item, "service")}
                    className="pf-active-filter-button-delete"
                  >
                    <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg">
                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { handleChange, options, filters, ...rest } = this.props
    return (
      <div className="profinder-industry-selector-wrapper">
        <MultiSelector
          text="Palvelu"
          filters={filters}
          filter="service"
          options={options}
          target="service"
          handleChange={handleChange}
          {...rest}
        >
          <div>
            <input
              className="pf-industry-search-box"
              type="text"
              value={this.state.search}
              onChange={this.handleSearchChange}
              placeholder="Etsi..."
            />

            {this.renderActiveSelections()}

            <div className="pf-options-list-container">{this.renderOptions()}</div>
          </div>
        </MultiSelector>
      </div>
    )
  }
}

ProfinderIndustryMultiSelector.propTypes = {
  toggleSelector: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clearFilters: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  visible: PropTypes.bool.isRequired
}

export default ProfinderIndustryMultiSelector
