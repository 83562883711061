export default {
  0: "#d15371", // muutos
  1: "#ae416a", // marjanpunainen
  2: "#7a65a7", // lila violetti
  3: "#345ca3", // työ
  4: "#638ec8", // usva
  5: "#44746a", // havunvihreä
  6: "#a5ca7d", // kokemus
  7: "#f5dc7c", // häive
  8: "#f3d14c", // idea
  9: "#e9b15b", // paahde
}
