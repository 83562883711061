import Immutable from "seamless-immutable"
import {
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_ERROR,
  GLOBAL_SEARCH_OK,
  SEARCH_STRING_CHANGE,
  FILTER_CHANGE,
  STATE_CHANGE,
} from "../actions/searchActions"

const initialState = Immutable({
  loading: false,
  result: [],
  error: null,
  value: "",
  searchString: "",
  filters: {
    region: [],
    industry: [],
    service: [], // Profinder industry / service
    personnel: [],
    form: [],
    revenue: [],
    startYearFrom: "",
    startYearTo: "",
    companies: true,
    branches: true,
    active: true,
  },
})

export default function(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_SEARCH:
      return state.merge({ loading: true, result: [] })
    case GLOBAL_SEARCH_OK:
      return state.merge({ result: action.data, loading: false, value: action.value })
    case GLOBAL_SEARCH_ERROR:
      return state.merge({ error: action.error, loading: false, result: [] })
    case SEARCH_STRING_CHANGE:
      return state.merge({ searchString: action.searchString })
    case STATE_CHANGE:
      return state.merge({ ...action.newState })
    case FILTER_CHANGE:
      return state.merge({ filters: action.newFilters }, { deep: true })
    default:
      return state
  }
}
