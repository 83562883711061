import React from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import Arrow from "../../assets/polygon-alas.svg"

const Select = ({
  options,
  value,
  onChange,
  defaultValue,
  variant,
  isMulti,
  valueAsObj,
  ...rest
}) => {
  const getOptionValue = obj => {
    return obj.value
  }

  const getOptionLabel = obj => {
    return obj.label
  }

  const DropdownIndicator = () => (
    <img alt="" style={{ width: "14px", height: "14px" }} src={Arrow} />
  )

  let customStyles = undefined
  let components = {}

  const getBgColor = () => {
    if (variant === "grey") return "#F7F7F7"
    return "#fff"
  }

  if (variant === "borderless") {
    customStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          background: "transparent",
          border: "none",
          outline: "none",
          paddingRight: "1rem",
          boxShadow: state.isFocused ? "0 0 0 1px #28487f" : "none",
        }
      },
      singleValue: provided => ({
        ...provided,
        display: "flex",
        position: "relative",
        alignItems: "center",
        transform: "none",
        maxWidth: "none",
        fontSize: "3.5rem",
        fontWeight: 900,
        color: "#333",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      container: provided => ({
        ...provided,
        outline: "none",
        cursor: "pointer",
        padding: "0 1rem",
      }),
    }
    components = { DropdownIndicator }
  } else {
    customStyles = {
      container: provided => ({
        ...provided,
        width: "100%",
        cursor: "pointer",
      }),
      control: (provided, state) => ({
        ...provided,
        background: getBgColor(),
        border: "1px solid #e1e1e1",
        borderColor: state.isFocused ? "#28487f" : "#E1e1e1",
        boxShadow: state.isFocused ? "0 0 0 1px #28487f" : "none",
        width: "100%",
      }),
    }
  }

  let valueObj = options ? options.find(opt => opt.value === value) : undefined
  if (isMulti) valueObj = options ? options.find(opt => opt.label === value) : undefined
  const defaultVal = options ? options.find(opt => opt.value === defaultValue) : undefined

  return (
    <ReactSelect
      {...rest}
      styles={customStyles}
      onChange={onChange}
      components={components}
      defaultValue={defaultVal}
      value={isMulti || valueAsObj ? value : valueObj}
      options={options}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isSearchable={false}
      isMulti={isMulti}
    />
  )
}
Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
}

Select.defaultProps = {
  className: "",
  variant: "",
}

export default Select
