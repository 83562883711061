import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import MultiSelector from "./MultiSelector"
import "./FoundedMultiSelector.css"

class RevenueMultiSelector extends PureComponent {
  render() {
    const { handleChange, filters, ...rest } = this.props
    const filtersObj = { startYearFrom: filters[0], startYearTo: filters[1] }
    return (
      <div className="founded-selector-wrapper">
        <MultiSelector text="Rekisteröity" filters={filtersObj} filter="founded" {...rest}>
          <ul>
            <label>
              Vuodesta:
              <input
                type="number"
                value={filtersObj.startYearFrom}
                onChange={e => handleChange(e.target.value, "startYearFrom")}
              />
            </label>
            <label>
              Vuoteen:
              <input
                type="number"
                value={filtersObj.startYearTo}
                onChange={e => handleChange(e.target.value, "startYearTo")}
              />
            </label>
          </ul>
        </MultiSelector>
      </div>
    )
  }
}

RevenueMultiSelector.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleSelector: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearFilters: PropTypes.func.isRequired,
}

export default RevenueMultiSelector
