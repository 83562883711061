import React, { Component } from "react"
import { Seo } from "../components/Seo"
import Styles from "./TextContent.module.css"

class ServiceInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <section className={[Styles.pageContent, Styles.container].join(" ")}>
        <h2>Tietoa Palvelusta</h2>
        <p>
          Business Tampereen yritystietopalvelu kerää yhteen ajantasaiset tiedot Tampereen
          kaupunkiseudun yrityksistä perustuen eri rekistereihin ja yritysten niihin ilmoittamiin
          tietoihin.
        </p>
        <p>
          Palvelun tiedot kaupunkiseudun yrityskannasta, aktiivisista yrityksistä sekä
          rekisteröidyistä ja lopetetuista yrityksistä perustuvat YTJ:n, Kaupparekisterin,
          Profinder yritystiedon ja Alma Talent Tietopalveluiden lähteisiin. Lisäksi palvelussa
          käytetään Verohallinnon (yritysten maksamat verot)
          ja Business Finlandin (myönnetty TKI-rahoitus) tuottamia tietoja.
        </p>
        <p>
          Palvelusta on mahdollista hakea tietoa yksittäisistä yrityksistä, eri hakukriteerien
          avulla muodostettuja yrityslistauksia ja visualisoituja tietonäkymiä.
        </p>
        <h3>Aktiiviset yritykset</h3>
        <p>
          Yritys on aktiivinen, kun se on rekisteröity kaupparekisteriin sekä vähintään yhteen
          toiseen rekisteriin (ALV-rekisteriin, ennakkoperintärekisteriin, tai
          työnantajarekisteriin) tai yrityksellä on vähintään yksi tilinpäätös viimeiseltä kolmelta
          tilikaudelta, eikä sillä ole mitään erityistilannetta (konkurssi, saneeraus, selvitystila,
          toiminta keskeytynyt tai toiminta lakannut). Viimeisen kuuden kuukauden sisään perustetut
          yritykset ovat automaattisesti aktiivisia.
        </p>
        <h3>Rekisteröidyt yritykset</h3>
        <p>
          Rekisteröidyt yritykset näyttää Kaupparekisteriin rekisteröityjen yritysten lukumäärän
          valituilla hakukriteereillä (kaupunkiseudun kunta, vuosi, toimiala). Oletuksena etusivulla
          näkyy tieto kaikista kuluvan vuoden aikana rekisteröidyistä yrityksistä kaupunkiseudulla.
        </p>
        <h3>Rekisteristä poistetut</h3>
        <p>
          Rekisteristä poistetut ovat yrityksiä, jotka eivät täytä palvelun aktiivisen yrityksen
          määritelmää.
        </p>
        <p>
          Yrityskannan tiedot päivittyvät kerran viikossa Profinder yritystietopalvelun datalla.
        </p>
        <Seo title="Tietoa palvelusta" />
      </section>
    )
  }
}

export default ServiceInfo
