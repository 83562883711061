import React, { Component } from "react"
import { Seo } from "../components/Seo"
import Styles from "./TextContent.module.css"

class DataProtectionInfo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <section className={[Styles.pageContent, Styles.container].join(" ")}>
        <h1>Business Tampereen yritystietopalvelun tietosuojaseloste</h1>
        <div>
          <h4>Rekisterin nimi:</h4>
          <p>Business Tampereen Yritystietopalvelu</p>
        </div>
        <div>
          <h4>Rekisterin pitäjä:</h4>
          <p>
            Tampereen kaupunkiseudun elinkeino- ja kehitysyhtiö Tredea Oy, (Business Tampere,
            y-tunnus 2252888-5)
          </p>
          <p>Kelloportinkatu 1 B, 33100 Tampere</p>
          <a href="https://www.businesstampere.fi">https://www.businesstampere.fi</a>
        </div>
        <div>
          <h4>Yhteyshenkilö:</h4>
          <p>Noora Vaaraniemi</p>
          <a href="mailto:noora.vaaraniemi@businesstampere.com">
            noora.vaaraniemi@businesstampere.com
          </a>
          <p>puh. 041 435 0883</p>
        </div>
        <div>
          <h4>Tietosuojavastaava:</h4>
          <p>Merja Maijala</p>
          <br />
          <a href="mailto:merja.maijala@businesstampere.com">merja.maijala@businesstampere.com</a>
          <br />
          <p>puh. 050 486 1253</p>
        </div>
        <div>
          <h3>Miksi keräämme tietoja ja mihin käytämme niitä?</h3>
          <p>
            Business Tampereen yritystietopalveluun on koottu tiedot Tampereen seudun yrityksistä.
            Järjestelmän tarkoitus on tuoda tietoa Tampereen seudun elinkeinokentästä ja
            yrityspohjasta siitä kiinnostuneille. Järjestelmässä voidaan suorittaa yrityshakuja
            perustuen avoimiin tietoaineistoihin sekä Profinder yritystietoon ja Alma Talent Tietopalveluun.
          </p>
        </div>
        <div>
          <h3>Mitä tietoa rekisterissä on?</h3>
          <p>
            Rekisteri sisältää Tampereen seudulla toimivien yritysten tietoja seuraavasti: Yrityksen
            nimi, y-tunnus, kotipaikka, postiosoite, käyntiosoite, toimiala, puhelin,
            sähköpostiosoite, kotiosoite, toimitusjohtajan nimi, yritysmuoto, rekisteritiedot
            kaupparekisterin, verohallinnon perustietojen, ennakkoperintärekisterin ja
            työnantajarekisterien osalta, liikevaihto, liikevaihdon prosenttimuutos, liikevoiton
            prosentuaalinen muutos, liiketoiminnan tulos, prosentuaalinen omavaraisuus,
            henkilöstömäärä. Tätä tietoa käsitellään rekisterinpitäjän oikeutetun edun perusteella.
          </p>
        </div>
        <div>
          <h3>Mistä tietoa saadaan?</h3>
          <p>
            Tiedot palveluun tuodaan Profinder yritystietopalvelusta ja Alma Talent Tietopalvelusta. Tiedot päivittyvät kerran
            viikossa.
          </p>
        </div>
        <br />
        <div>
          <p>
            Tiedot ovat avoimesti saatavilla kelle tahansa järjestelmässä vierailevalle henkilölle.
          </p>
        </div>
        <div>
          <h3>Rekisteröidyn oikeudet</h3>
          <p>Rekisteröidyllä on seuraavat oikeudet:</p>
          <ul>
            <li>saada tietoa henkilötietojensa käsittelystä</li>
            <li>saada pääsy tietoihin (oikeus tarkastaa rekisterin sisältämät henkilötiedot)</li>
            <li>oikaista tietoja</li>
            <li>poistaa tiedot (tulla unohdetuksi)</li>
            <li>rajoittaa tietojen käsittelyä</li>
            <li>siirtää tiedot järjestelmästä toiseen</li>
            <li>vastustaa tietojen käsittelyä</li>
            <li>
              olla joutumatta automaattisen päätöksenteon kohteeksi ilman lainmukaista perustetta
            </li>
          </ul>
        </div>
        <div>
          <p>
            Rekisteröidyllä oikeus on milloin tahansa peruuttaa antamansa suostumus henkilötietojen
            käsittelyyn. Kun haluat käyttää oikeuksiasi rekisteröitynä, sinun tulee lähettää
            kirjallinen pyyntö yhteyshenkilölle (
            <a href="mailto:merja.maijala@businesstampere.com">merja.maijala@businesstampere.com</a>
            ). Mikäli koet, että rikomme henkilötietoja käsitellessämme voimassa olevaa
            tietosuojalainsäädäntöä, sinulla on oikeus tehdä valitus{" "}
            <a href="http://www.tietosuoja.fi/fi/index/yhteystiedot.html">
              kansalliselle tietosuojaviranomaiselle
            </a>
            .
          </p>
        </div>
        <div>
          <h3>Automaattinen päätöksenteko ja profilointi</h3>
          <p>Emme käytä tietoja automaattiseen päätöksentekoon tai profilointiin.</p>
        </div>
        <div>
          <h3>Rekisterin suojaaminen</h3>
          <p>
            Järjestelmä toimii Business Tampereen palveluntarjoajan palvelimilla, joihin on pääsy
            ainoastaan teknisellä ylläpitohenkilöstöllä. Palvelimet sijaitsevat EU / ETA alueella,
            vartioidussa ja palosuojatussa palvelintilassa.
          </p>
        </div>
        <div>
          <h3>Säilytys, arkistointi ja hävittäminen</h3>
          <p>
            Yhteystiedot talletetaan tietojärjestelmään, jossa niitä säilytetään toiminnan kannalta
            tarpeellisen ajan. Tietoja ei erikseen arkistoida.
          </p>
        </div>
        <div>
          <h3>Evästeet</h3>
          <p>
            Saatamme käyttää evästeitä parantaaksemme kokemustasi yhteisössämme. Eväste on pieni
            tiedosto, joka säilytetään tietokoneellasi vieraillessasi sivustoillamme. Evästeiden
            avulla saamme tietoa siitä, milloin palaat palveluumme. Evästeillä varmistetaan myös,
            että sivustot ovat nopeita ja turvallisia. Niiden avulla voimme myös ratkaista
            palvelussamme olevia ohjelmointivirheitä. Mikäli et halua käyttää evästeitä, voit
            poistaa ne muuttamalla selaimesi asetuksia. Vastuuvapautuslauseke: Palvelu ei
            välttämättä toimi asianmukaisesti ilman evästeitä.
          </p>
        </div>
        <div>
          <h3>Linkit</h3>
          <p>
            Palvelussa saattaa olla tulevaisuudessa linkkejä muille kuin Business Tampereen
            ylläpitämille sivustoille. Emme ole vastuussa näiden sivustojen sisällöstä tai
            tietosuojasta. Suosittelemme sinua aina lukemaan sivustojen omat tietosuojalausunnot.
          </p>
        </div>
        <div>
          <h3>Muutokset tähän tietosuojalausuntoon</h3>
          <p>
            Saatamme muuttaa tätä tietosuojalausuntoa aika ajoin. Kun teemme suurempia muutoksia
            tähän tietosuojalausuntoon, ilmoitamme siitä sivustoillamme.
          </p>
        </div>
        <div>
          <h3>Kysymyksiä?</h3>
          <p>
            Mikäli sinulla on kysyttävää, voit aina lähettää meille sähköpostia osoitteeseen{" "}
            <a href="mailto:noora.vaaraniemi@businesstampere.com">
              noora.vaaraniemi@businesstampere.com
            </a>
            . Business Tampere vastaa kahden arkipäivän kuluessa rekisteriä koskeviin kysymyksiin ja
            tietoja koskeviin palautteisiin.
          </p>
        </div>
        <Seo title="Tietosuojaseloste" />
      </section>
    )
  }
}

export default DataProtectionInfo
