import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import LoadingSpinner from "./LoadingSpinner"

const style = {
  backgroundColor: "white",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  transition: "250ms all",
  zIndex: 9,
  pointerEvents: "none",
}

const LoadingOverlay = ({ visible }) => {
  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    if (visible) setOpacity(0.666)
    if (!visible) setOpacity(0)
  }, [visible])
  // if (!visible) return null
  return (
    <div style={{ ...style, opacity }}>
      <LoadingSpinner loading={visible} />
    </div>
  )
}

LoadingOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
}

export default LoadingOverlay
