import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { capitalize } from "../util/string"
import Styles from "./SearchResult.module.css"

const SearchResult = props => {
  const { result, location } = props
  if (!result) return null
  if (result.toimipaikka_nimi) {
    return (
      <div className={`${Styles.searchResult} ${Styles.branch}`}>
        <div className={Styles.left}>
          <Link
            to={{
              pathname: `/sivutoimipaikka/${result.toimipaikkaId}`,
              state: { prevPath: location.pathname, search: location.search },
            }}
            className={Styles.name}
          >
            <p>{result.virallinen_nimi}</p>
          </Link>
          <p className={Styles.region}>{`Toimipaikka: ${capitalize(
            result.kayntios_kunta_toimipaikka
          )}`}</p>
        </div>
        <div className={Styles.center}>
          <p className={Styles.businessId}>{result.toimipaikka_nimi}</p>
          <p className={Styles.region}>Toimipaikan nimi</p>
        </div>
        <p className={Styles.industry}>{result.tol5_selite}</p>
      </div>
    )
  }

  return (
    <div className={`${Styles.searchResult} ${Styles.company}`}>
      <div className={Styles.left}>
        <Link
          to={{
            pathname: `/yritys/${result.y_tunnus}`,
            state: { prevPath: location.pathname, search: location.search },
          }}
          className={Styles.name}
        >
          <p>{result.virallinen_nimi}</p>
        </Link>
        <p className={Styles.region}>
          Kotipaikka:
          {` ${capitalize(result.kotikunta)}`}
        </p>
      </div>
      <div className={Styles.center}>
        <p className={Styles.businessId}>{result.y_tunnus}</p>
        <p className={Styles.region}>Y-tunnus</p>
      </div>
      <p className={Styles.industry}>{result.tol2_selite}</p>
    </div>
  )
}

SearchResult.propTypes = {
  result: PropTypes.shape().isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
}

export default SearchResult
