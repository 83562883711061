import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LoadingSpinner from "../LoadingSpinner"
import List from "../RevenueWidget/List"
import RevenueSelector from "../RevenueSelector"
import * as TaxListActions from "../../actions/taxListActions"
import "../Top10Employers/Top10Employers.css"
import NotAvailable from "../NotAvailable"
import { ButtonLink } from "../ButtonLink"

const Top10TaxPayers = props => {
  const { loading, list, location, params, changeTaxParams, renderModal } = props
  const { region, industry, year, revenue } = params

  const onChangeTaxClass = value => {
    changeTaxParams({
      revenue: value,
      region,
      industry,
      year,
    })
  }

  const renderContent = () => {
    if ((!list || !list.length) && !loading) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }

    return (
      <React.Fragment>
        <div>
          <RevenueSelector value={revenue} onChange={onChangeTaxClass} className="dropdown" />
        </div>
        <div className="revenue-list-wrapper">
          {loading ? (
            <div className="loading-container">
              <LoadingSpinner loading={loading} />
            </div>
          ) : (
            <List loading={loading} list={list} location={location} />
          )}
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="revenue-widget">
        <div className="revenue-info">{renderModal}</div>
        {renderContent()}
        <div className="button-container">
          <ButtonLink
            to={{
              pathname: "/top500/veronmaksajat",
              state: { prevPath: location.pathname },
            }}
            disabled={!list || !list.length}
          >
            Näytä Top 500
          </ButtonLink>
        </div>
      </div>
    </React.Fragment>
  )
}

Top10TaxPayers.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  params: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  changeTaxParams: PropTypes.func.isRequired,
  renderModal: PropTypes.element,
}

const mapDispatchToProps = dispatch => ({
  changeTaxParams: params => dispatch(TaxListActions.changeParams(params)),
})

export default connect(null, mapDispatchToProps)(Top10TaxPayers)
