import Immutable from "seamless-immutable"
import {
  FETCH_REGION_STATISTICS,
  FETCH_REGION_STATISTICS_OK,
  FETCH_REGION_STATISTICS_ERROR,
} from "../actions/regionStatisticsActions"

const initialState = Immutable({
  loading: false,
  data: {},
  error: null,
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_REGION_STATISTICS:
      return state.merge({ loading: true })
    case FETCH_REGION_STATISTICS_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_REGION_STATISTICS_ERROR:
      return state.merge({ error: action.error, loading: false })
    default:
      return state
  }
}
