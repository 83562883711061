import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import MultiSelector from "./MultiSelector"
import "./PersonnelMultiSelector.css"
import Checkbox from "../Checkbox/Checkbox"

class PersonnelMultiSelector extends PureComponent {
  state = {
    selectNoneObject: {
      label: "Ei tiedossa",
      value: {
        minPersonnel: -1,
        maxPersonnel: -1,
      },
    },
  }

  renderOptions = () => {
    const { handleChange, options, filters } = this.props
    if (options) {
      return options.map(o => (
        <li key={o.value.minPersonnel}>
          <Checkbox
            label={o.label}
            id={o.label}
            onClick={() => handleChange(o, "personnel")}
            checked={filters.some(f => f.value.maxPersonnel === o.value.maxPersonnel)}
          />
        </li>
      ))
    }
    return null
  }

  renderSelectNone = () => {
    const { handleChange, filters } = this.props
    const { selectNoneObject } = this.state
    return (
      <li key="select-none">
        <Checkbox
          label="Ei tiedossa"
          onClick={() => handleChange(selectNoneObject, "personnel")}
          checked={filters.some(f => f.value.minPersonnel === -1)}
        />
      </li>
    )
  }

  render() {
    const { handleChange, options, filters, ...rest } = this.props
    const { selectNoneObject } = this.state
    let allOptions
    if (options) allOptions = options.concat(selectNoneObject)
    return (
      <div className="personnel-selector-wrapper">
        <MultiSelector
          text="Henkilöstö"
          filters={filters}
          filter="personnel"
          options={allOptions}
          target="personnel"
          handleChange={handleChange}
          {...rest}
        >
          <ul>
            {this.renderOptions()}
            {this.renderSelectNone()}
          </ul>
        </MultiSelector>
      </div>
    )
  }
}

PersonnelMultiSelector.propTypes = {
  toggleSelector: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clearFilters: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
}

export default PersonnelMultiSelector
