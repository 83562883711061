import React from "react"
import PropTypes from "prop-types"
import Styles from "./SelectionButtons.module.css"
import PinkButton from "./PinkButton"

const SelectionButtons = ({ selected, selection, onClick }) => {
  const style = {
    margin: "0 8px 8px",
    flex: "1 1 auto",
  }

  return (
    <div className={Styles.buttonContainer}>
      {selection.map(({ id, title }) => (
        <PinkButton
          key={id}
          noFill={selected !== id}
          label={title}
          onClick={() => onClick(id)}
          style={style}
        />
      ))}
    </div>
  )
}

SelectionButtons.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SelectionButtons
