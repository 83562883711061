import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as isEmpty from "lodash.isempty"
import { buildSearchUrl } from "../util/string"
import {
  RegionMultiSelector,
  IndustryMultiSelector,
  ProfinderIndustryMultiSelector,
  PersonnelMultiselector,
  CompanyFormMultiSelector,
  RevenueMultiSelector,
  FoundedMultiSelector,
} from "./MultiSelectors"
import * as SearchActions from "../actions/searchActions"
import logo from "../assets/business-tampere-logo.png"
import haku from "../assets/haku.svg"
import classes from "./Search.module.css"
const Immutable = require("seamless-immutable").static

class Search extends Component {
  state = {
    selectorOpen: false,
    selector: "",
  }

  handleChange = event => this.props.handleChange(event.target.value)

  // Handling for filters with multiple options
  handleFilterChange = (element, filter, replace) => {
    const { filters, handleFilterChange } = this.props
    let newFilters = Immutable.asMutable(filters, { deep: true })
    // Toggle off behavior
    if (newFilters[filter].some(f => f.label === element.label)) {
      newFilters[filter] = newFilters[filter].filter(f => f.label !== element.label)
    } else {
      // Toggle on behavior
      newFilters[filter] = newFilters[filter].concat([element])
    }
    // "Select all" behavior
    if (replace) newFilters = filters.merge({ [filter]: element })
    handleFilterChange(newFilters)
  }

  // Handling for filters with for example manual input
  handleSingleFilterChange = (element, filter) => {
    const { filters, handleFilterChange } = this.props
    const newFilters = filters.merge({ [filter]: element })
    handleFilterChange(newFilters)
  }

  clearFilters = filter => this.props.clearFilters(this.props.searchState, filter)

  toggleSelector = selector => {
    const { selectorOpen } = this.state
    this.setState({ selectorOpen: !selectorOpen, selector })
  }

  filtersEmpty = () => {
    const { filters } = this.props
    const {
      region,
      service,
      industry,
      personnel,
      form,
      revenue,
      startYearFrom,
      startYearTo
    } = filters
    return (
      isEmpty(region) &&
      isEmpty(service) &&
      isEmpty(industry) &&
      isEmpty(personnel) &&
      isEmpty(form) &&
      isEmpty(revenue) &&
      isEmpty(startYearFrom) &&
      isEmpty(startYearTo)
    )
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSearchClick()
    }
  }

  onSearchClick = () => {
    const { searchString, filters, history } = this.props

    const link = `/haku/${buildSearchUrl(searchString, filters)}`
    history.push(link)
  }

  render() {
    const { selectorOpen, selector } = this.state
    const { searchString, filters, options, profinderIndustries } = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.logoContainer}>
          <img className={classes.searchLogo} src={logo} alt="logo" />
        </div>
        <div
          className={classes.overlay}
          role="none"
          style={{ visibility: selectorOpen ? "visible" : "hidden" }}
          onKeyDown={this.handleKeyPress}
          onClick={() => this.toggleSelector("")}
        />
        <form>
          <div className={classes.search}>
            <img className={classes.iconButton} src={haku} alt="" />
            <input
              type="text"
              value={searchString}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              placeholder="Hae aktiivisia yrityksiä kotipaikalla, yrityksen nimellä tai Y-tunnuksella"
              className={classes.input}
            />
          </div>
          <div className={classes.filtersWrapper}>
            <div className={classes.filters}>
              <RegionMultiSelector
                visible={selectorOpen && selector === "region"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.region}
                clearFilters={this.clearFilters}
                options={options.regionOptions}
              />
              <IndustryMultiSelector
                visible={selectorOpen && selector === "industry"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.industry}
                clearFilters={this.clearFilters}
                options={options.industryOptions}
              />
              <ProfinderIndustryMultiSelector
                visible={selectorOpen && selector === "service"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.service}
                clearFilters={this.clearFilters}
                options={profinderIndustries}
              />
              <PersonnelMultiselector
                visible={selectorOpen && selector === "personnel"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.personnel}
                clearFilters={this.clearFilters}
                options={options.personnelOptions}
              />
              <CompanyFormMultiSelector
                visible={selectorOpen && selector === "form"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.form}
                clearFilters={this.clearFilters}
                options={options.formOptions}
              />
              <RevenueMultiSelector
                visible={selectorOpen && selector === "revenue"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleFilterChange}
                filters={filters.revenue}
                clearFilters={this.clearFilters}
                options={options.revenueOptions}
              />
              <FoundedMultiSelector
                visible={selectorOpen && selector === "founded"}
                toggleSelector={this.toggleSelector}
                handleChange={this.handleSingleFilterChange}
                filters={[filters.startYearFrom, filters.startYearTo]}
                clearFilters={this.clearFilters}
              />
            </div>
          </div>
          <div className={classes.controls}>
            <button
              className={classes.searchButton}
              type="button"
              disabled={this.filtersEmpty() && !searchString}
              onClick={this.onSearchClick}
            >
              Hae
            </button>
            <button
              className={classes.clearAll}
              type="button"
              onClick={() => this.clearFilters("all")}
            >
              Tyhjennä
            </button>
          </div>
        </form>
      </div>
    )
  }
}

Search.propTypes = {
  options: PropTypes.shape().isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  searchString: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  searchState: PropTypes.shape().isRequired,
  profinderIndustries: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  options: state.filterOptions.data,
  profinderIndustries: state.profinderIndustries.data,
  filters: state.search.filters,
  searchString: state.search.searchString,
  searchState: state.search,
})

const mapDispatchToProps = dispatch => ({
  handleFilterChange: newFilters => dispatch(SearchActions.handleFilterChange(newFilters)),
  handleChange: searchString => dispatch(SearchActions.handleChange(searchString)),
  clearFilters: (prevState, filter) => dispatch(SearchActions.clearFilters(prevState, filter)),
  setState: newState => dispatch(SearchActions.setState(newState)),
  search: (ss, f) => dispatch(SearchActions.globalSearch(ss, f)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
