import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LoadingSpinner from "../LoadingSpinner"
import List from "../RevenueWidget/List"
import RevenueSelector from "../RevenueSelector"
import * as EmployerListActions from "../../actions/employerListActions"
import "./Top10Employers.css"
import NotAvailable from "../NotAvailable"
import { ButtonLink } from "../ButtonLink"

const Top10Employers = props => {
  const { loading, list, location, params, changeEmployerParams } = props
  const { region, industry, year, revenue } = params

  const onChangeEmployerClass = value => {
    changeEmployerParams({
      revenue: value,
      region,
      industry,
      year,
    })
  }

  const renderContent = () => {
    if ((!list || !list.length) && !loading) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }

    return (
      <React.Fragment>
        <div>
          <RevenueSelector value={revenue} onChange={onChangeEmployerClass} className="dropdown" />
        </div>
        <div className="revenue-list-wrapper">
          {loading ? (
            <div className="loading-container">
              <LoadingSpinner loading={loading} />
            </div>
          ) : (
            <List loading={loading} list={list} location={location} />
          )}
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="revenue-widget">
        {renderContent()}
        <div className="button-container">
          <ButtonLink
            to={{
              pathname: "/top500/tyollistajat",
              state: { prevPath: location.pathname },
            }}
            disabled={!list || !list.length}
          >
            Näytä Top 500
          </ButtonLink>
        </div>
      </div>
    </React.Fragment>
  )
}

Top10Employers.propTypes = {
  loading: PropTypes.bool.isRequired,
  // TODO: specify actual object shape (if necessary)
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  params: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  changeEmployerParams: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  changeEmployerParams: params => dispatch(EmployerListActions.changeParams(params)),
})

export default connect(null, mapDispatchToProps)(Top10Employers)
