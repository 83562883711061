import React from "react"
import PropTypes from "prop-types"
import classes from "./Radio.module.css"

const Radio = props => {
  const { id, label, checked, onClick, colorCode, name } = props

  const renderColorCode = () => {
    if (!colorCode) return null
    return <div className={classes.colorBox} style={{ backgroundColor: colorCode }} />
  }

  return (
    <label htmlFor={id ? id : label} className={classes.container}>
      <span>{label}</span>
      {renderColorCode()}
      <input
        id={id ? id : label}
        type="radio"
        name={name}
        onClick={onClick}
        checked={checked}
        onChange={() => {}}
      />
      <span className={classes.checkmark}>{checked && <span className={classes.dot} />}</span>
    </label>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  colorCode: PropTypes.string,
  name: PropTypes.string.isRequired,
}

Radio.defaultTypes = {
  id: null,
  colorCode: null,
}

export default Radio
