import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classes from "./List.module.css"

class List extends PureComponent {
  render() {
    const { loading, list, location } = this.props
    return (
      <div className={classes.list}>
        <ol className={loading ? classes.invisible : classes.visible}>
          {list.map((company, index) => (
            <li className={classes.row} key={company.virallinen_nimi}>
              <span className={classes.rank}>{`${index + 1}.`}</span>
              <Link
                className={classes.name}
                to={{
                  pathname: `/yritys/${company.y_tunnus || company.ytunnus}`,
                  state: { prevPath: location.pathname },
                }}
              >
                {company.virallinen_nimi}
              </Link>
              <span className={classes.value}>{company.value}</span>
            </li>
          ))}
        </ol>
      </div>
    )
  }
}

List.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
}

export default List
