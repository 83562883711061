import Immutable from "seamless-immutable"
import { FETCH_COMPANY, FETCH_COMPANY_OK, FETCH_COMPANY_ERROR } from "../actions/companyActions"

const initialState = Immutable({
  loading: false,
  data: {},
  error: null,
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY:
      return state.merge({ loading: true })
    case FETCH_COMPANY_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_COMPANY_ERROR:
      return state.merge({ error: action.error, loading: false })
    default:
      return state
  }
}
