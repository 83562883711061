import React from "react"
import PropTypes from "prop-types"
import { formatMoney } from "../../util/string"
import Styles from "./Finances.module.css"
import RibbonContainer from "../RibbonContainer"
import { Select } from "../Select"

const Cell = ({ header, text }) => (
  <div className={Styles.cell}>
    <p className={Styles.header}>{header}</p>
    <p className={Styles.huge}>{text}</p>
  </div>
)

Cell.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const CompanyFinances = ({ allFinances, selectedYear, yearOptions, onChange }) => {
  const finances = allFinances[selectedYear.value]

  const renderTitle = () => {
    if (allFinances.length === 1) {
      return `taloustiedot ${
        finances && finances.tilinpaatos_vuosi ? finances.tilinpaatos_vuosi : ""
      }`
    }
    return (
      <div className={Styles.title}>
        <h1>taloustiedot</h1>
        <Select
          options={yearOptions}
          value={selectedYear}
          onChange={onChange}
          variant="borderless"
          valueAsObj
        />
      </div>
    )
  }

  return (
    <RibbonContainer title={renderTitle()}>
      {finances ? (
        <div className={Styles.finances}>
          <div className={Styles.row}>
            <Cell header="Liikevaihto" text={formatMoney(finances.lvnorm)} />
            <Cell header="Liiketoiminnan tulos" text={formatMoney(finances.liiketulos)} />
          </div>
          <div className={Styles.row}>
            <Cell header="Liikevaihdon muutos-%" text={`${finances.lvkasvupros}%`} />
            <Cell header="Liikevoitto-%" text={`${finances.liiketulosproslv}%`} />
            <Cell
              header="Henkilöstö"
              text={finances.hlokeskim === 0 ? "Ei tietoa" : finances.hlokeskim}
            />
            {/* <Cell header="Omavaraisuus" text={`${finances.omavaraisuusastepros}%`} /> */}
          </div>
        </div>
      ) : (
        <div className={Styles.notFound}>
          <span>Taloustietoja ei löydetty</span>
        </div>
      )}
    </RibbonContainer>
  )
}

CompanyFinances.propTypes = {
  allFinances: PropTypes.arrayOf(PropTypes.shape()),
  yearOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedYear: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CompanyFinances
