import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import "react-dropdown/style.css"
import { Select } from "../../Select"

class RdiFundingSelector extends PureComponent {
  render() {
    const options = [
      { value: "1", label: "Yhteensä" },
      { value: "2", label: "Lainat" },
      { value: "3", label: "Avustukset" },
    ]

    const { onChange, value } = this.props

    return (
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Valitse rahoitustyyppi"
        variant="grey"
      />
    )
  }
}

RdiFundingSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}

RdiFundingSelector.defaultProps = {
  className: "",
}

export default RdiFundingSelector
