export const scrolledToBottomListener = callback => {
  const scrollHandler = () => {
    const { body, documentElement } = document
    const windowHeight =
      "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
    const footerHeight = document.getElementById("footer").offsetHeight
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      documentElement.clientHeight,
      documentElement.scrollHeight,
      documentElement.offsetHeight
    )
    const windowBottom = windowHeight + window.pageYOffset + footerHeight * 2
    if (windowBottom >= docHeight && typeof callback === "function") {
      callback()
    }
  }

  return {
    start: () => window.addEventListener("scroll", scrollHandler),
    stop: () => window.removeEventListener("scroll", scrollHandler),
  }
}
