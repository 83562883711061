import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "../Modal"
import PinkButton from "../PinkButton"
import { Table } from "./Table"
import Styles from "./ChartTable.module.css"

export const ChartTable = ({ data, columns, className, children, title }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const CLOSE_BUTTON_ID = "close-table-modal"
  const onTriggerClick = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        const btn = document.getElementById(CLOSE_BUTTON_ID)
        btn && btn.focus()
      })
    }
  }, [modalOpen])

  return (
    <div className={`${className} ${Styles.wrapper}`}>
      <PinkButton
        className={Styles.trigger}
        label="Näytä tiedot taulukossa"
        onClick={onTriggerClick}
      />
      <Modal isOpen={modalOpen}>
        {title && <h1>{title}</h1>}
        <Table data={data} columns={columns} />
        <div className={Styles.textContent}>{children}</div>
        <div className={Styles.controls}>
          <PinkButton
            id={CLOSE_BUTTON_ID}
            className={Styles.closeButton}
            onClick={onTriggerClick}
            label="Sulje"
          />
        </div>
      </Modal>
    </div>
  )
}

ChartTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, heading: PropTypes.string }))
    .isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
}

ChartTable.defaultProps= {
  data: [],
  className: '',
  children: null,
}