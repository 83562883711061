import React from "react"
import PropTypes from "prop-types"
import Styles from "./PinkButton.module.css"

const PinkButton = ({ label, noFill, style, onClick, disabled, className, ...rest }) => (
  <button
    className={`${noFill ? Styles.noFill : Styles.button} ${disabled &&
      Styles.disabled} ${className}`}
    style={style}
    type="button"
    onClick={!disabled ? onClick : () => {}}
    disabled={disabled}
    {...rest}
  >
    {label}
  </button>
)

PinkButton.defaultProps = {
  noFill: false,
  style: null,
  onClick: null,
  disabled: false,
  className: "",
}

PinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  noFill: PropTypes.bool,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default PinkButton
