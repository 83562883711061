import React from "react"
import PropTypes from "prop-types"
import Styles from "./NotAvailable.module.css"

const NotAvailable = ({ label }) => (
  <div>
    <div className={Styles.notAvailableWrapper}>
      <span className={Styles.notAvailable}>{label}</span>
    </div>
  </div>
)

NotAvailable.defaultProps = {
  label: "",
}

NotAvailable.propTypes = {
  label: PropTypes.string,
}
export default NotAvailable
