import React from "react"
import PropTypes from "prop-types"
import Styles from "./Info.module.css"
import RibbonContainer from "../RibbonContainer"
import { Link } from "react-router-dom"

const Row = ({ header, text }) => (
  <tr>
    <td>{header}</td>
    <td>{text}</td>
  </tr>
)

Row.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
}

const check = text => text || "-"
const checkAddress = (address, zip, city) => {
  if (!address || !zip || !city) {
    return "-"
  }
  return `${address}, ${zip} ${city}`
}

const Title = ({ company, branch, businessId }) => (
  <div className={Styles.title}>
    <div className={Styles.section}>
      {businessId ? (
        <Link className={`${Styles.company} ${Styles.link}`} to={`/yritys/${businessId}`}>
          {company}
        </Link>
      ) : (
        <h1 className={Styles.company}>{company}</h1>
      )}
      <span className={Styles.secondary}>Yritys</span>
    </div>
    <div className={Styles.section}>
      <span className={Styles.branch}>{` - `}</span>
    </div>
    <div className={Styles.section}>
      <span className={Styles.branch}>{branch}</span>
      <span className={Styles.secondary}>Toimipaikka</span>
    </div>
  </div>
)

Title.propTypes = {
  company: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  businessId: PropTypes.string,
}

const BranchInfo = ({ branch }) => (
  <RibbonContainer
    title={
      <Title
        company={branch.virallinen_nimi}
        branch={branch.toimipaikka_nimi}
        businessId={branch.company?.y_tunnus}
      />
    }
  >
    <div className={Styles.info}>
      <table className={Styles.left}>
        <tbody>
          {/* <Row header="Y-tunnus" text={check(branch.company.y_tunnus)} /> */}
          <Row
            header="Postiosoite"
            text={checkAddress(
              branch.postiosoite_toimipaikka,
              branch.postios_pnro_toimipaikka,
              branch.postios_kunta_toimipaikka
            )}
          />
          <Row
            header="Käyntiosoite"
            text={checkAddress(
              branch.kayntiosoite_toimipaikka,
              branch.kayntios_pnro_toimipaikka,
              branch.kayntios_kunta_toimipaikka
            )}
          />
          <Row header="Toimiala" text={check(branch.tol5_selite)} />
          <Row header="Puhelin" text={check(branch.puhnro_toimipaikka)} />
          {/* TODO: Display when personnel info is available again */}
          {/* <Row header="Henkilökunta" text={check(branch.hlokunta)} /> */}
        </tbody>
      </table>
      <table className={Styles.right}>
        <tbody></tbody>
      </table>
    </div>
  </RibbonContainer>
)

BranchInfo.propTypes = {
  branch: PropTypes.shape().isRequired,
}

export default BranchInfo
