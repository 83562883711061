import React from "react"
import ReactModal from "react-modal"
import PropTypes from "prop-types"

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 24,
    maxWidth: 400,
    borderRadius: 0,
    border: 0,
    backgroundColor: '#fff',
    boxShadow: '1px 2px 5px rgba(0,0,0,0.1)'
  },
  overlay: {
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    zIndex: 3,
  },
}

export const Modal = ({ isOpen, children }) => {
  ReactModal.setAppElement("#root")

  const onOpen = () => {
    document.body.style.overflow = "hidden"
  }

  const onClose = () => {
    document.body.removeAttribute("style")
  }

  return (
    <ReactModal onAfterOpen={onOpen} style={modalStyles} onAfterClose={onClose} isOpen={isOpen}>
      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
}

Modal.defaultProps = {
  isOpen: false,
}
