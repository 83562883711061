import React from "react"
import PropTypes from "prop-types"
import classes from "./Checkbox.module.css"

const Checkbox = props => {
  const { id, label, checked, onClick, colorCode } = props

  const renderColorCode = () => {
    if (!colorCode) return null
    return <div className={classes.colorBox} style={{ backgroundColor: colorCode }} />
  }

  return (
    <label htmlFor={id ? id : label} className={classes.container}>
      <span>{label}</span>
      {renderColorCode()}
      <input
        id={id ? id : label}
        type="checkbox"
        onClick={onClick}
        checked={checked}
        onChange={() => {}}
      />
      <span className={classes.checkmark} />
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  colorCode: PropTypes.string,
}

Checkbox.defaultTypes = {
  id: null,
  colorCode: null,
}

export default Checkbox
