export const companiesToGeojson = (companies: Object[]) => ({
  type: "FeatureCollection",
  features: companies
    .map(
      c =>
        c.latitude &&
        c.longitude && {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [c.longitude, c.latitude],
          },
          properties: {
            ...c,
          },
        }
    )
    .filter(Boolean),
})
