import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { PAGE_TITLE } from "../../util/constants"

export const Seo = ({ title }) => {
  const renderTitleStart = () => {
    if (!title) return ""
    return `${title} | `
  }
  return (
    <Helmet>
      <title>{`${renderTitleStart()} ${PAGE_TITLE}`}</title>
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
}

Seo.defaultProps = {
  title: "",
}
