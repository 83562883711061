import React from "react"
import PropTypes from "prop-types"
import "./RibbonContainer.css"

const RibbonContainer = props => {
  const { title, children, headerSize } = props
  return (
    <div className="ribbon-container">
      <div className={`header ${headerSize}`}>
        {typeof title === "string" ? <h1>{title}</h1> : title}
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

export default RibbonContainer

RibbonContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired,
  headerSize: PropTypes.string,
}

RibbonContainer.defaultProps = {
  headerSize: "normal",
}
