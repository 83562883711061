import React from "react"
import PropTypes from "prop-types"
import "./LoadingSpinner.css"

const LoadingSpinner = ({ loading }) => (
  <div className={`spinner-container ${loading ? "visible" : "invisible"}`}>
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)
LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default LoadingSpinner
