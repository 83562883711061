import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Select } from "./Select"

class YearSelector extends PureComponent {
  render() {
    const thisYear = new Date().getFullYear()
    const options = [{ value: 0, label: "Kaikki vuodet" }]
    for (let i = 0; i < 10; i += 1) {
      const year = thisYear - i
      options.push({
        value: year,
        label: year,
      })
    }
    const { onChange, value } = this.props

    return <Select options={options} onChange={onChange} value={value} variant="borderless" />
  }
}

YearSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
}

YearSelector.defaultProps = {
  className: "",
  placeholderClassName: "",
}

export default YearSelector
