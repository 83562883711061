import React from "react"
import PropTypes from "prop-types"
import Styles from "./Table.module.css"

export const Table = ({ data, columns }) => {
  return (
    <table className={Styles.table}>
      <thead>
        <tr className={Styles.headerRow}>
          {columns && columns.map(col => <th key={col.id}>{col.heading}</th>)}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map(row => (
            <tr key={columns && row[columns[0].id]}>
              {columns && columns.map(({ id }) => <td>{row[id]}</td>)}
            </tr>
          ))}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, heading: PropTypes.string }))
    .isRequired,
}

Table.defaultProps = {
  data: [],
}
