import Immutable from "seamless-immutable"
import {
  FETCH_TOP10_TAXPAYERS,
  FETCH_TOP10_TAXPAYERS_ERROR,
  FETCH_TOP10_TAXPAYERS_OK,
  FETCH_TOP10_TAXPAYERS_CHANGE_PARAMS,
  FETCH_TOP10_TAXPAYERS_CLEAR_PARAMS,
} from "../actions/taxListActions"

const initialState = Immutable({
  loading: false,
  data: [],
  error: null,
  params: { revenue: "", region: null, industry: null, year: null },
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOP10_TAXPAYERS:
      return state.merge({ loading: true })
    case FETCH_TOP10_TAXPAYERS_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_TOP10_TAXPAYERS_ERROR:
      return state.merge({ error: action.error, loading: false })
    case FETCH_TOP10_TAXPAYERS_CHANGE_PARAMS:
      return state.merge({ params: action.data })
    case FETCH_TOP10_TAXPAYERS_CLEAR_PARAMS:
      return state.merge({ params: initialState.params })
    default:
      return state
  }
}
